// import 'sweetalert2/dist/sweetalert2.all.min.js';
import Cleave from "cleave.js";
// import 'cleave.js/dist/cleave.min.css';

document.addEventListener('DOMContentLoaded', function () {
    // Validadores
    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function validateData(data) {
        var re = /^\d{2}\/\d{2}\/\d{4}$/;
        return re.test(data);
    }

    // Máscaras
    var formato_data = new Cleave('#crm-data-lp', {
        date: true,
        datePattern: ['d','m', 'Y']
    });

    //Captura de Dados - LP
    $("#crm-btn-lp").on("click", function () {
        url = window.location.href;
        componente = $(this).data("componente");
        origem = $(this).data("origem");
        nome = $("#crm-nome-lp").val();
        email = $("#crm-email-lp").val();
        data = $("#crm-data-lp").val();
        validacaoNome = false;
        validacaoEmail = false;
        validacaoData = false;

        if( !$("#crm-nome-lp").is(":required")) {
            validacaoNome = true;
        }

        if( !$("#crm-data-lp").is(":required")) {
            validacaoData = true;
        }

        if ( nome == "" && $("#crm-nome-lp").is(":required") ) {
            document.getElementById("crm-aviso-nome-lp").innerHTML = "*Preenchimento obrigatório.";
            document.getElementById("crm-aviso-nome-lp").classList.add("exibe-aviso");
        } else {
            validacaoNome = true;
        }
        if ( data != "" && $("#crm-data-lp").is(":required") ) {
            if(validateData(data)) {
                validacaoData = true;
                document.getElementById("crm-aviso-data-lp").classList.remove("exibe-aviso");
            } else {
                document.getElementById("crm-aviso-data-lp").innerHTML = "*Digite uma data válida.";
                document.getElementById("crm-aviso-data-lp").classList.add("exibe-aviso");
            }
        } else {
            document.getElementById("crm-aviso-data-lp").innerHTML = "*Preenchimento obrigatório.";
            document.getElementById("crm-aviso-data-lp").classList.add("exibe-aviso");
        }
        if (email != "" && $("#crm-email-lp").is(":required")) {
            if (validateEmail(email)) {
                validacaoEmail = true;
                document.getElementById("crm-aviso-email-lp").classList.remove("exibe-aviso");
            } else {
                document.getElementById("crm-aviso-email-lp").innerHTML = "*Digite um e-mail válido.";
                document.getElementById("crm-aviso-email-lp").classList.add("exibe-aviso");
            }
        } else {
            document.getElementById("crm-aviso-email-lp").innerHTML = "*Preenchimento obrigatório.";
            document.getElementById("crm-aviso-email-lp").classList.add("exibe-aviso");
        }

        if(validacaoNome && validacaoEmail && validacaoData) {
            enviaDadosLeadComData(nome, email, data, origem, componente, url);
        }
    });

    $("#crm-email-lp").on("keypress", function () {
        document.getElementById("crm-aviso-email-lp").classList.remove("exibe-aviso");
    });
    $("#crm-nome-lp").on("keypress", function () {
        document.getElementById("crm-aviso-nome-lp").classList.remove("exibe-aviso");
    });
    $("#crm-data-lp").on("keypress", function () {
        document.getElementById("crm-aviso-data-lp").classList.remove("exibe-aviso");
    });

    //Envio dos Dados com campo Data de Nascimento
    function enviaDadosLeadComData(nome, email, data, origem, componente, url) {
        console.log("Dados enviados.", email, origem);

        $.ajax({
            url: "/salva-contato-landing-page.php", // URL em que será feita a requisição
            method: "POST", // Método da Requisição (GET ou POST)
            dataType: "json", // Formato da resposta esperada
            data: {
                // Dados que serão enviados
                email: email,
                nome: nome,
                data: data,
                componente: componente,
                origem: origem,
                url: btoa(url),
            },
            success: function (data) {
                console.log(data);
                // Em caso de sucesso, os dados são o que retornou no sucesso
                if (data.status == 'success') {
                    if( document.getElementById("crm-aviso-email-lp") != null ) {
                        document.getElementById("crm-aviso-email-lp").classList.remove("exibe-aviso");
                    }
                    $("#crm-email-lp").val('');
                    $("#crm-nome-lp").val('');
                    $("#crm-data-lp").val('');
                    Swal.fire({
                        // CADASTRO CONCLUIDO COM SUCESSO
                        title: "Sucesso :)", // TITULO DO POP-UP
                        text: "Seu cadastro foi concluído e em breve você receberá nossas comunicações!", // TEXTO INSERIDO NO POP-UP
                        icon: "success", // LOGO DO POP-UP (SUCESSO)
                        confirmButtonText: "OKAY! CONTINUE.", // BOTAO DE ENCERRAMENTO
                    });
                } else {
                    if (data.servico == "contato-existente") {
                        Swal.fire({
                            title: "Olha só...", // TITULO DO POP-UP
                            text: "Você já se cadastrou em nossa newsletter. Aguarde para receber nossas comunicações!", // TEXTO INSERIDO NO POP-UP
                            icon: "warning", // LOGO DO POP-UP (ATENÇÃO)
                            confirmButtonText: "Tudo bem, Então!", // BOTAO DE ENCERRAMENTO
                        });
                    } else {
                        Swal.fire({
                            title: "Olha só...", // TITULO DO POP-UP
                            text: "Você já se cadastrou em nossa newsletter. Aguarde para receber nossas comunicações!", // TEXTO INSERIDO NO POP-UP
                            icon: "info", // LOGO DO POP-UP (INFORMAÇÃO)
                            confirmButtonText: "Tudo bem, Então!", // BOTAO DE ENCERRAMENTO
                        });
                    }
                }
            },
            error: function (request) {
                if (request) {
                    Swal.fire({
                        title: "Ops... :(", // TITULO DO POP-UP
                        text: "Não foi possível te cadastrar. Tente novamente mais tarde.", // TEXTO INSERIDO NO POP-UP
                        icon: "error", // LOGO DO POP-UP (ERRO)
                        confirmButtonText: "OKAY! VOLTAREI DEPOIS.", // BOTAO DE ENCERRAMENTO
                    });
                }
            },
        });
    }
});