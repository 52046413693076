import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', () => {
    AOS.init();

    const homeCarrossel = () => {
        const swiperBanner = new Swiper('.swiper.banner-home', {
            modules: [Pagination],
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });  
    }
    homeCarrossel();
    
    /**
     * Define the breakpoint where Swiper will be destroyed and switches to grid layout.
     */
    // const breakpoint = window.matchMedia( '(min-width:31em)' ); // 992px - md
    const breakpoint = window.matchMedia( '(min-width:55em)' ); // 1400 - xxl
    
    /**
     * Create Swiper variable.
     */
    let swiperOnlyMobile;
     
    // Carrossel inside Posts grid section, only on mobile
    const mobileCarrossel = () => {
        swiperOnlyMobile = new Swiper('.carrossel-triplo .swiper', {
            modules: [Pagination],
            slidesPerView: 1,
            spaceBetween: 0,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            wrapperClass: 'swiper-wrapper',
        });  
    }
    /**
     * Create Swiper variable.
     */
    let swiperMultipleOnlyMobile;
    
    /**
     * Create function to check the breakpoint.
     * If its larger than const breakpoint, it will start Swiper, otherwise it will destroy Swiper.
     */
    const breakpointChecker = () => {
        if ( breakpoint.matches === true ) {
            if ( swiperOnlyMobile !== undefined ) swiperOnlyMobile.destroy(true, true);
            if ( swiperMultipleOnlyMobile !== undefined ) swiperMultipleOnlyMobile.destroy(true, true);
        } else if ( breakpoint.matches === false ) {
            mobileCarrossel();
            mobileMultipleCarrossel();
        }
    }
    
    // Carrossel inside Posts grid section, only on mobile
    const mobileMultipleCarrossel = () => {
        swiperMultipleOnlyMobile = new Swiper('.carrossel-multiplo-mobile .swiper', {
            slidesPerView: 'auto',
            spaceBetween: 30,
            wrapperClass: 'swiper-wrapper',
        });  
    }
    
    breakpoint.addListener( breakpointChecker );
    breakpointChecker();
    
    const tresItensCarrossel = () => {
        new Swiper('.swiper.carrossel-tres-itens', { 
            modules: [Navigation, Pagination],
            breakpoints: {
                340: {
                  slidesPerView: 1,
                  spaceBetween: 0
                },
                769: {
                  slidesPerView: 3,
                  spaceBetween: 0
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });  
    }
    tresItensCarrossel();
});