document.addEventListener('DOMContentLoaded', function () {
    const mainMenu = document.getElementById('main-menu');
    const dropdownLink = document.getElementById('dropdown-link');
    const dropdownMenu = document.getElementById('dropdown-menu');
    const toggler = document.querySelector('.navbar-toggler');

    toggler.addEventListener('click', function () {
        const isExpanded = this.getAttribute('aria-expanded') === 'true';
        
        // Alterna a classe do menu
        mainMenu.classList.toggle('show', !isExpanded);

        // Atualiza o atributo aria-expanded corretamente
        this.setAttribute('aria-expanded', !isExpanded);
    });

    function toggleDropdownMobile() {        
        mainMenu.classList.toggle('open');
        mainMenu.classList.remove('start-d-none');
        mainMenu.classList.toggle('show');
        dropdownLink.classList.toggle('open');
        dropdownMenu.classList.toggle('open');
        dropdownMenu.classList.toggle('start-d-none');
    }
      
    // Dropdown menu desktop
    function openDropdownDesktop() { 
        dropdownLink.classList.add('open');
        dropdownMenu.classList.add('open');
        dropdownMenu.classList.remove('start-d-none');
    }
    function closeDropdownDesktop() {  
        dropdownLink.classList.remove('open');
        dropdownMenu.classList.remove('open');
        dropdownMenu.classList.remove('start-d-none');
    }
      
    // Products dropdown on Main menu
    if ( $( window ).width() < 992 ) { // less than lg - Mobile devices
        document.querySelectorAll('#dropdown-menu .submenu, #dropdown-item').forEach(el => {
            el.addEventListener('click', toggleDropdownMobile);
        });
    } else { // more than lg - Desktop devices
        document.querySelectorAll('#dropdown-menu .submenu').forEach(el => {
            el.addEventListener('mouseenter', openDropdownDesktop);
            el.addEventListener('mouseleave', closeDropdownDesktop);
        });
        document.getElementById('dropdown-item').addEventListener('mouseenter', openDropdownDesktop);
        document.getElementById('dropdown-item').addEventListener('mouseleave', closeDropdownDesktop);
    }

    // Item do menu ativo
    document.querySelectorAll('#main-menu a').forEach(a => {
        if (a.getAttribute('href').endsWith(location.pathname)) {
            a.classList.add('menu-item-active');
        }
    });

    // Menu toggle animation on Mobile
    document.querySelectorAll('.navbar-toggler').forEach(el => {
        el.addEventListener('click', function () {
            this.classList.toggle('is-open');
            this.classList.toggle('is-closed');
        });
    });

    // Scroll Progress Bar - Post
    window.addEventListener('scroll', scrollPost);

    function scrollPost() {
        const winScroll = document.documentElement.scrollTop || document.body.scrollTop;
        const alturaConteudoPost = document.querySelector('.conteudo-post')?.offsetHeight || 0;
        const scrolled = (winScroll / (alturaConteudoPost + 90)) * 100;
        const progressBar = document.getElementById("header-progress-bar");
        if (progressBar) {
            progressBar.style.width = scrolled + "%";
        }
    }
});
