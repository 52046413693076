import $ from 'jquery';
import 'bootstrap/js/dist/modal';
import Isotope from 'isotope-layout'

window.$ = $;
window.jQuery = $;

document.addEventListener('DOMContentLoaded', function () {
    var siteurl = window.location.protocol + "//" + window.location.host;
    var sitepath = siteurl + window.location.pathname;

    // Query padrão de posts (Load More e Categorias)
    let posts_por_carregamento;
    let categoria_post = '*';
    let total_posts_carregados = 0;

    // Inicializando o grid com o Isotope
    let grid_posts = null;
    if ($('#carregar-posts').length) {
        grid_posts = $('#carregar-posts').isotope({
            itemSelector: '.card-post',
            percentPosition: true,
            filter: categoria_post,
            fitRows: {
                columnWidth: '.card-post',
                gutter: 30,
            },
            layoutMode: 'fitRows',
        });
    }

    // Função para recarregar os posts
    const recarregarGridPosts = (categoria_post) => {
        if (categoria_post === '*') {
            $('.grid-posts-destaque').height('auto').show();
        } else {
            $('.grid-posts-destaque').height(0).hide();
        }

        // Atualizando o filtro do Isotope
        if (grid_posts) {
            grid_posts.isotope({ filter: categoria_post });
        }
    };

    // Chamando a função recarregarGridPosts quando a página for carregada
    $(window).on('load', function() {
        recarregarGridPosts(categoria_post);
    });

    let ajaxQuery = (categoria_post, total_posts_carregados) => {
        posts_por_carregamento = 8;
        total_posts_carregados += posts_por_carregamento;
        categoria_post = categoria_post.replace('.', '');

        $.ajax({
            url: siteurl + '/core/partials/query-ajax-posts.php',
            method: 'POST',
            async: false,
            dataType: 'html',
            data: {
                numero_posts: total_posts,
                posts_por_carregamento: total_posts_carregados,
                categoria_post: categoria_post
            },
            success: function(data) {
                $('#carregar-posts').html(data.split('</article>'));
                let posts_restantes_por_categoria = $('#posts-restantes').val();

                if (posts_restantes_por_categoria === '0') {
                    $('#carregar-posts').append('<p>Não há mais posts.</p>');
                }

                recarregarGridPosts(categoria_post);
                esconderBotaoCarregarMais(posts_restantes_por_categoria, total_posts_carregados);
            }
        })
    };

    const esconderBotaoCarregarMais = (posts_restantes_por_categoria, total_posts_carregados) => {
        if (posts_restantes_por_categoria <= total_posts_carregados) {
            $('#carregar-mais-posts').prop('disabled', true).text('Todos os posts foram carregados');
        } else {
            $('#carregar-mais-posts').prop('disabled', false).text('Carregar mais').append('<span class="visually-hidden" aria-hidden="true"> posts</span>');
        }
    }

    let filtroPostsPorCategoria = (event) => {
        $('.card-post').removeClass('estilo-inicial');
        $('#carregar-posts').addClass('categoria-selecionada');

        categoria_post = $(event.target).data('filter').replace('.', '');
        total_posts_carregados = 0;
        ajaxQuery(categoria_post, total_posts_carregados);
        recarregarGridPosts(categoria_post);
        posts_por_carregamento = 0;
    };

    let alternarFiltro = (event) => {
        $('.filtro-abas .active').removeClass('active');
        $(event.target).addClass('active');
    };

    $('#filtro-categorias-blog .item-texto').click(filtroPostsPorCategoria);
    $('.filtro-abas .item-texto').click(alternarFiltro);

    $('#carregar-mais-posts').click(function() {
        $('#carregar-posts').addClass('grid-carregado');
        let posts_por_carregamento = 8;

        if ($('#categoria-geral').hasClass('active')) {
            total_posts_carregados += posts_por_carregamento - 3;
        } else {
            total_posts_carregados += posts_por_carregamento;
        }

        categoria_post = $('#filtro-categorias-blog .item-texto.active').attr('id').replace('categoria-', '');
        if(categoria_post === undefined || categoria_post === '' || categoria_post === null || categoria_post === 'geral') {
            categoria_post = '*';
        }
        ajaxQuery(categoria_post, total_posts_carregados);
    });

    $('#campo-pesquisa-geral').keyup(function() {
        let termo_pesquisado = $(this).val();

        if (termo_pesquisado.length !== 0) {
            $('#modal-busca').addClass('modal-com-resultado');
            $('#modal-busca .modal-footer').attr('hidden', true).attr('aria-hidden', 'true');
            $('#carregar-resultados').attr('hidden', false).attr('aria-hidden', 'false');
        } else {
            $('#modal-busca').removeClass('modal-com-resultado');
            $('#carregar-resultados').attr('hidden', true).attr('aria-hidden', 'true');
            $('#modal-busca .modal-footer').attr('hidden', false).attr('aria-hidden', 'false');
        }

        $.ajax({
            url: siteurl + '/core/partials/query-ajax-pesquisa.php',
            method: "POST",
            async: false,
            dataType: "html",
            data: {
                termo: termo_pesquisado,
                numero_resultados: 3,
                tipo_conteudo: 'geral'
            },
            success: function(data) {
                $('#carregar-resultados').html(data.split('</article>'));
            }
        });
    });

    $('#campo-pesquisa-especifica').keyup(buscarConteudos);

    $(window).on('load', function() {
        if (window.location.href.indexOf('busca') > -1) {
            $('.search-toggler').prop('disabled', true);
            $('#texto-inicial-sem-resultado').attr('hidden', true).attr('aria-hidden', 'true');
            $('.btn-outros-resultados').attr('hidden', true).attr('aria-hidden', 'true');
        }
    });

    function buscarConteudos() {
        let termo_pesquisado = $(this).val();

        if (typeof termo_pesquisado !== 'undefined') {
            $('#texto-inicial-sem-resultado').attr('hidden', true).attr('aria-hidden', 'true');
            $('#modal-busca').addClass('modal-com-resultado');
            $('#modal-busca .modal-footer').attr('hidden', true).attr('aria-hidden', 'true');
            $('#carregar-resultados').attr('hidden', false).attr('aria-hidden', 'false');
        } else {
            $('#texto-inicial-sem-resultado').attr('hidden', false).attr('aria-hidden', 'false');
            $('#modal-busca').removeClass('modal-com-resultado');
            $('#carregar-resultados').attr('hidden', true).attr('aria-hidden', 'true');
            $('#modal-busca .modal-footer').attr('hidden', false).attr('aria-hidden', 'false');
        }

        let tipo_conteudo = $('.secao-filtro-abas .item-texto.active').attr('id').replace('categoria-', '');

        $('.filtro-abas .item-texto').click(function() {
            tipo_conteudo = $(this).attr('id').replace('categoria-', '');
        });

        $.ajax({
            url: siteurl + '/core/partials/query-ajax-pesquisa.php',
            method: "POST",
            async: false,
            dataType: "html",
            data: {
                termo: termo_pesquisado,
                tipo_conteudo: tipo_conteudo
            },
            success: function(data) {
                $('#carregar-resultados-especificos').html(data.split('</article>'));
                $('#n-resultado').html($('#total-resultados').val());
            }
        });
    }

    $('#filtro-busca .item-texto').click(function() {
        let categoria_filtro = $(this).attr('id').replace('categoria-', '');


        if (categoria_filtro === 'geral') {
            $('.item-resultado').attr('hidden', false).attr('aria-hidden', 'false');
        } else {
            $('.item-resultado').attr('hidden', true).attr('aria-hidden', 'true');
            $('.item-resultado.' + categoria_filtro).attr('hidden', false).attr('aria-hidden', 'false');
        }
    });

    $('#modal-busca .btn-limpar').click(function() {
        $('#modal-busca').removeClass('modal-com-resultado');
        $('#carregar-resultados').attr('hidden', true).attr('aria-hidden', 'true');
        $('#modal-busca .modal-footer').attr('hidden', false).attr('aria-hidden', 'false');
    });
});