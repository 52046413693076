import 'bootstrap/js/dist/tooltip';

window.addEventListener('load', function() {
    $('[data-toggle="tooltip"]').tooltip();

    $('#cep').blur(function() {
        var cep = $(this).val();
        if (cep && cep.length == 8) {
            buscaCEP(cep);
        }
    });

});


/* Click de envio de form */
function enviaDados() {

    nome = document.getElementById('nome').value;
    perfil = document.getElementById('perfil').value;
    motivo_contato = document.getElementById('motivo_contato').value;
    email = document.getElementById('email').value;
    ddd = document.getElementById('ddd').value;
    telefone = document.getElementById('telefone').value;
    contato_telefonico = document.querySelector('input[name="contato_telefonico"]:checked').value;
    cep = document.getElementById('cep').value;
    endereco = document.getElementById('endereco').value;
    numero = document.getElementById('numero').value;
    complemento = document.getElementById('complemento').value;
    uf = document.getElementById('uf').value;
    cidade = document.getElementById('cidade').value;
    comentarios = document.getElementById('comentarios').value;

    $.ajax({
        type: "POST",
        url: "../api/sendmail.php",
        dataType: "json",
        data: {
            nome: nome,
            perfil: perfil,
            motivo_contato: motivo_contato,
            email: email,
            ddd: ddd,
            telefone: telefone,
            contato_telefonico: contato_telefonico,
            cep: cep,
            endereco: endereco,
            numero: numero,
            complemento: complemento,
            uf: uf,
            cidade: cidade,
            comentarios: comentarios

        },
        success: function(resp) {
            alert(resp.mensagem);
            if (resp.tipo == 'sucesso') {
                window.location.reload();
            } else {
                buttonsContainer.show();
            }
        },
        error: function(xhr, ajaxOptions, thrownError) {
            alert("Ocorreu um erro inesperado, tente novamente mais tarde.");
        }
    });

    return false;

};

function validaBox1() {
    if (!$("#nome").val()) {
        mensagemErro($("#nome"), "Informe qual o seu nome.");
        return false;
    }

    if (!$("#perfil").val()) {
        mensagemErro($("#perfil"), "Informe em qual perfil você se encaixa.");
        return false;
    }

    $('h1').text('COMO PODEMOS TE AJUDAR?');

    alteraBox(1);
}

function validaBox2() {

    if (!$("#motivo_contato").val()) {
        mensagemErro($("#motivo_contato"), "Informe qual o motivo do seu contato.");
        return false;
    }

    $('h1').text('DADOS DE CONTATO');

    alteraBox(1);
}

function validaBox3() {

    if (!$("#email").val()) {
        mensagemErro($("#email"), "Informe qual o seu e-mail.");
        return false;
    }

    if (!validaEmail($("#email").val())) {
        mensagemErro($("#email"), "E-mail Inválido.");
        return false;
    }

    if (!$("#ddd").val()) {
        mensagemErro($("#ddd"), "Informe o seu DDD.");
        return false;
    }

    if ($("#ddd").val().length < 2) {
        mensagemErro($("#ddd"), "DDD Inválido");
        return false;
    }

    if (!$("#telefone").val()) {
        mensagemErro($("#telefone"), "Informe o seu telefone.");
        return false;
    }

    if ($("#telefone").val().length < 8) {
        mensagemErro($("#telefone"), "Telefone Inválido");
        return false;
    }

    $('h1').text('DADOS DE CONTATO');

    alteraBox(1);
}

function validaBox4() {

    if (!$("#cep").val()) {
        mensagemErro($("#cep"), "Informe o seu CEP.");
        return false;
    }

    if (!$("#endereco").val()) {
        mensagemErro($("#endereco"), "Informe o seu endereço.");
        return false;
    }


    if (!$("#numero").val()) {
        mensagemErro($("#numero"), "Informe o seu número.");
        return false;
    }

    $('h1').text('DADOS DE CONTATO');

    alteraBox(1);
}

function validaBox5() {

    if ($("input[name='inlineRadioOptions']:checked").length == 0) {
        alert("Responda o aceite dos termos.");
        return false;
    } else if ($("input[name='inlineRadioOptions']:checked")[0].value == "não") {
        alert("É necessário aceitar as condições para enviar seu contato.");
        return false;
    } else {
        return true;
    }
}

function atualizaMotivoContato(perfil) {
    var opcoes = ["Reação Adversa", "Sugestão/Elogio", "Crítica/Reclamação de produto", "Informações de produtos/medicamentos", "Oferecimento de serviço", "Solicitação de visita (Representante)", "Solicitação de visita (Vendedor)"];
    var html = '<option value="" selected disabled>Selecione uma opção</option>';

    if (perfil === 'Consumidor') {
        opcoes.splice(4);
    }

    opcoes.forEach(function(opcao) {
        html += '<option value="' + opcao + '">' + opcao + '</option>';
    });

    $("#motivo_contato").html(html);
}

function alteraBox(cont) {
    var index = $('.box.ativo').index();

    $('.error').remove();
    $('.erro').removeClass('erro');

    $('.box')
        .removeClass('ativo')
        .eq(index + cont)
        .addClass('ativo');

    $('.paginacao span')
        .removeClass('ativo')
        .eq(index + cont)
        .addClass('ativo');
}

function SomenteNumero(e) {
    var tecla = (window.event) ? event.keyCode : e.which;
    if ((tecla > 47 && tecla < 58)) return true;
    else {
        if (tecla == 8 || tecla == 0) return true;
        else return false;
    }
}

function buscaCEP(cep) {

    var endereco = jQuery("input[name=endereco]");
    var uf = jQuery("input[name=uf]");
    var cidade = jQuery("input[name=cidade]");
    var numero = jQuery("input[name=numero]");

    endereco.val('');
    uf.val('');
    cidade.val('');

    $.getJSON("https://viacep.com.br/ws/" + cep + "/json", function(dados) {

        if (dados.erro) {
            return false;
        }

        endereco.val(dados.logradouro);
        uf.val(dados.uf);
        cidade.val(dados.localidade);
        numero.focus();
    });
}

function mensagemErro(objeto, mensagem) {
    var tagErro = '<span class="error">' + mensagem + '</span>';
    $('.error').remove();
    $('.erro').removeClass('erro');

    $(objeto).parent().append(tagErro);
    $(objeto).addClass('erro');
    $(objeto).focus();
    //setTimeout("$(function(){$('.error').fadeOut(1500);})", 1000);
}

function validaEmail(email) {
    var reg = /^([0-9a-zA-Z]+([_.-]?[0-9a-zA-Z]+)*@[0-9a-zA-Z]+[0-9,a-z,A-Z,.,-]*(.){1}[a-zA-Z]{2,4})+$/;
    if (reg.test(email) == false)
        return false;
    return true;
}

function isArquivoValido(arquivo) {
    var reg = /^(.*)\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
    return reg.test(arquivo);
}