document.addEventListener('DOMContentLoaded', function () {
    const toggler = document.querySelector('.navbar-toggler');
    const mainMenu = document.getElementById('main-menu');

    toggler.addEventListener('click', function () {
        const isExpanded = this.getAttribute('aria-expanded') === 'true';
        
        // Alterna a classe do menu
        mainMenu.classList.toggle('show', !isExpanded);

        // Atualiza o atributo aria-expanded corretamente
        this.setAttribute('aria-expanded', !isExpanded);
    });

    function toggleDropdownMobile() {;
        const dropdownLink = document.getElementById('dropdown-link');
        const dropdownMenu = document.getElementById('dropdown-menu');
        
        mainMenu.classList.toggle('open');
        mainMenu.classList.remove('start-d-none');
        mainMenu.classList.toggle('show');
        dropdownLink.classList.toggle('open');
        dropdownMenu.classList.toggle('open');
        dropdownMenu.classList.toggle('start-d-none');
    }

    function openDropdownDesktop() {
        document.getElementById('dropdown-link').classList.add('open');
        const dropdownMenu = document.getElementById('dropdown-menu');
        dropdownMenu.classList.add('open');
        dropdownMenu.classList.remove('start-d-none');
    }

    function closeDropdownDesktop() {
        document.getElementById('dropdown-link').classList.remove('open');
        const dropdownMenu = document.getElementById('dropdown-menu');
        dropdownMenu.classList.remove('open');
        dropdownMenu.classList.add('start-d-none');
    }

    if (window.innerWidth < 992) {
        document.querySelectorAll('#dropdown-menu .submenu, #dropdown-item').forEach(el => {
            el.addEventListener('click', toggleDropdownMobile);
        });
    } else {
        document.querySelectorAll('#dropdown-menu .submenu').forEach(el => {
            el.addEventListener('mouseenter', openDropdownDesktop);
            el.addEventListener('mouseleave', closeDropdownDesktop);
        });
        document.getElementById('dropdown-item').addEventListener('mouseenter', openDropdownDesktop);
    }

    document.querySelectorAll('#main-menu a').forEach(a => {
        if (a.getAttribute('href').endsWith(location.pathname)) {
            a.classList.add('menu-item-active');
        }
    });

    document.querySelectorAll('.navbar-toggler').forEach(el => {
        el.addEventListener('click', function () {
            this.classList.toggle('is-open');
            this.classList.toggle('is-closed');
        });
    });

    window.addEventListener('scroll', scrollPost);

    function scrollPost() {
        const winScroll = document.documentElement.scrollTop || document.body.scrollTop;
        const alturaConteudoPost = document.querySelector('.conteudo-post')?.offsetHeight || 0;
        const scrolled = (winScroll / (alturaConteudoPost + 90)) * 100;
        const progressBar = document.getElementById("header-progress-bar");
        if (progressBar) {
            progressBar.style.width = scrolled + "%";
        }
    }

    function resizeHeader() {
        const scroll = window.scrollY;
        const body = document.body;
        const header = document.getElementById('header');

        if (scroll >= 50) {
            body.classList.remove('body-expand');
            body.classList.add('body-shrink');
            header.classList.remove('nav-expand');
            header.classList.add('nav-shrink');
        } else {
            body.classList.remove('body-shrink');
            body.classList.add('body-expand');
            header.classList.remove('nav-shrink');
            header.classList.add('nav-expand');
        }
    }

    resizeHeader();
    window.addEventListener('scroll', resizeHeader);
});
