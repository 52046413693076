$(document).ready(function ($) {
	// FAQ tabs
	// Seleciona todos os botões de FAQ
	var faqButtons = document.querySelectorAll('[data-faq]');
	// Função para alternar a exibição com display block/none e transição suave
	function toggleFaq(faqId) {
		var faqSection = document.getElementById(faqId);

		if (faqSection) {
			// Esconder todas as outras seções FAQ
			document.querySelectorAll('.faq-resposta-box').forEach(function (section) {
				section.classList.add('d-none');
			});

			// Exibir a seção atual
			faqSection.classList.remove('d-none');
		}
	}
	// Adiciona evento de clique a cada botão de FAQ
	faqButtons.forEach(function (button) {
		button.addEventListener('click', function () {
			var faqId = button.getAttribute('data-faq');

            if(faqId === 'faq-resposta-produto-todos') {
                document.querySelectorAll('.faq-resposta-box').forEach(function (section) {
                    section.classList.remove('d-none');
                });
            } else {
                toggleFaq(faqId);
            }

            this.scrollIntoView({ behavior: 'smooth' });
            faqButtons.forEach(function (button) {
                button.classList.remove('bg-secondary');
                button.classList.add('bg-primary');
            });
            this.classList.remove('bg-primary');
            this.classList.add('bg-secondary');
		});
	});
});