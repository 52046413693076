$(document).ready(function ($) {
     // BUSCA MENU FUNCIONAMENTO BASICO
     // <div class="col-search mx-3">
     $("#getPriceBotaoDesktop").before(`
     <div class="col-search">
     <button class="search-toggler btn btn-transparent" type="button" aria-controls="modal-busca" aria-expanded="false" aria-label="Toggle modal" aria-hidden="false">
          <span class="material-symbols-rounded icon-search">search</span>
          <span class="material-symbols-rounded icon-close">close</span>
          <span class="visually-hidden" aria-hidden="true">Abrir/fechar modal para pesquisa.</span>
     </button>
     </div>
     `);
 
     // Clique no icone da busca, mudar classe para o x e abrir ou fechar a busca
     $(".search-toggler").click(function () {
          $(this).toggleClass('active');
          $('.header-search').slideToggle('slow');
          $('#campo-pesquisa-geral').focus();
 
          $('#header').toggleClass('search-open');
          $('#campo-pesquisa-geral').val('');
          $('#carregar-resultados').empty();
          $('#carregar-resultados').attr('hidden', true);
     });
 
     $('#menuToggle, .btn-close-header-search').on('click', function () {
          $('.header-search').slideUp('slow');
          $('.search-toggler').removeClass('active');
          $('#campo-pesquisa-geral').val('');
          $('#carregar-resultados').empty();
          $('#carregar-resultados').attr('hidden', true);
     });
 
     // Buscar e exibir resultados ao digitar na barra de pesquisa
     $('#campo-pesquisa-geral').on('keyup', function () {
          const termo = $(this).val();
          if (termo.length > 2) {
               fetchResultadosBuscaMenu(termo);
          }
     });
 
     function fetchResultadosBuscaMenu(termo) {
          fetch('/core/motor-de-busca.php', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
               },
               body: new URLSearchParams({
                    'termo': termo,
                    'origem': 'menu'
               })
          })
               .then(response => response.json())
               .then(data => {
                    renderResultadosBuscaMenu(data);
                 // console.log(data);
               })
               .catch(error => console.error('Erro na busca:', error));
     }
 
     function renderResultadosBuscaMenu(data) {
          let resultadosHtml = '';
          $('#carregar-resultados').empty();
 
          if (data.resultadosMenu.length === 0) {
               resultadosHtml = '<p class="texto-sem-resultados">Não encontramos resultados para a sua busca.</p>';
               $('#carregar-resultados').html(resultadosHtml).attr('hidden', false);
               return;
          }
 
          data.resultadosMenu.forEach(item => {
               let tipoItem = item.tipo === 'produto' ? 'Produtos' : item.tipo === 'post' ? 'Blog' : 'Dúvidas frequentes';
               let imagemHtml = item.imagem ? `<img src="https://www.buscopan.com.br/assets/${item.tipo === 'post' ? 'images/blog/posts' : 'images/produtos'}/${item.imagem}" alt="${item.nome}" class="img-thumbnail me-3 p-2" style="width: 150px; height: 150px; object-fit: contain;">` : '';
 
               resultadosHtml += `
               <article class="item-resultado ${item.tipo}">
                    ${imagemHtml}
                    <div>
                         <p class="tipo-resultado">${tipoItem}</p>
                         <p class="fs-6 text-primary fw-bold titulo-resultado py-1">
                              <a href="${item.url}">${item.nome}</a>
                         </p>
                    </div>
               </article>`;
          });
 
          if (data.totals.totalGeral > 3) {
               resultadosHtml += `
                <div class="mostrar-mais d-flex justify-content-center align-items-center">
                    <a href="/busca.php?termo=${$('#campo-pesquisa-geral').val()}" class="btn btn-primary">
                        Ver outros ${data.totals.totalGeral - 3} resultados
                    </a>
                </div>`;
          }
 
          $('#carregar-resultados').html(resultadosHtml).attr('hidden', false);
     }
 
     // PARA PAGINA DE BUSCA (busca.php)
     if (window.location.href.indexOf('busca') > -1) {
          $('.search-toggler').prop('disabled', true);
          const termoInicial = $('#campo-pesquisa-especifica').val();
          if (termoInicial.length >= 0) {
               fetchResultadosBuscaPagina(termoInicial);
          }
     }
 
     $('#campo-pesquisa-especifica').on('keyup', function () {
          const termo = $(this).val();
          if (termo.length > 0) {
               fetchResultadosBuscaPagina(termo);
          } else {
               // Limpar os resultados e os contadores ao apagar o termo
               $('#carregar-resultados-especificos').empty();
               atualizarContadores({ totals: { totalGeral: 0, totalProdutos: 0, totalPosts: 0, totalFaq: 0 } });
          }
     });
 
     function fetchResultadosBuscaPagina(termo) {
          fetch('/core/motor-de-busca.php', {
               method: 'POST',
               headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
               body: new URLSearchParams({ 'termo': termo })
          })
               .then(response => response.json())
               .then(data => {
                    renderResultadosBuscaPagina(data);  // Renderizar os resultados
                    verificarEAtualizarAbaAtiva(data);  // Verificar e atualizar a aba ao carregar os resultados
                    aplicarFiltroCategoriaAtiva();      // Aplicar o filtro da categoria ativa
               })
               .catch(error => console.error('Erro na busca:', error));
     }
 
     function renderResultadosBuscaPagina(data) {
          let resultadosHtml = '';
          $('#carregar-resultados-especificos').empty();
 
          if (data.produtos.length === 0 && data.posts.length === 0 && data.faq.length === 0) {
               resultadosHtml = '<p class="texto-sem-resultados">Não encontramos resultados para a sua busca.</p>';
               $('#carregar-resultados-especificos').html(resultadosHtml);
          }
 
          // Renderizar os resultados de Produtos
          if (data.produtos.length > 0) {
               data.produtos.forEach(produto => {
                    resultadosHtml += `
            <article class="item-resultado produtos">
                ${produto.fotoProduto ? `<img src="https://www.buscopan.com.br/${produto.fotoProduto}" alt="${produto.nomeProduto}" class="img-thumbnail">` : ''}
                <div class="w-100">
                    <p class="tipo-resultado">Produtos</p>
                    <p class="fs-6 text-primary fw-bold titulo-resultado py-1">
                        <a href="${produto.urlProduto}">${produto.nomeProduto}</a>
                    </p>
                    <a href="${produto.urlProduto}" class="btn btn-primary mt-2">Saiba mais</a>
                </div>
            </article>`;
               });
          }
 
          // Renderizar os resultados de Posts
          if (data.posts.length > 0) {
               data.posts.forEach(post => {
                    resultadosHtml += `
            <article class="item-resultado posts">
                ${post.imagemPost ? `<img src="https://www.buscopan.com.br/assets/images/blog/posts/${post.imagemPost}" alt="${post.tituloPost}" class="img-thumbnail me-3">` : ''}
                <div>
                    <p class="tipo-resultado">Blog</p>
 
                    <p class="fs-6 text-primary fw-bold titulo-resultado py-1">
                        <a href="${post.urlPost.startsWith('/') ? post.urlPost : '/blog/' + post.urlPost}">${post.tituloPost}</a>
                    </p>
                </div>
            </article>`;
               });
          }
 
          // Renderizar os resultados de FAQ
          if (data.faq.length > 0) {
               data.faq.forEach(faq => {
                    resultadosHtml += `
            <article class="item-resultado faq">
                ${faq.fotoProduto ? `<img src="https://www.buscopan.com.br/assets/images/produtos/${faq.fotoProduto}" alt="${faq.pergunta}" class="img-thumbnail me-3">` : ''}
                <div>
                    <p class="tipo-resultado">Dúvidas frequentes</p>
                    <p class="fs-6 text-primary fw-bold titulo-resultado py-1">
                        <a href="/faq">${faq.pergunta}</a>
                    </p>
                </div>
            </article>`;
               });
          }
 
          $('#carregar-resultados-especificos').html(resultadosHtml);
          atualizarContadores(data);
          verificarEAtualizarAbaAtiva(data); // Verificar e atualizar a aba automaticamente
          aplicarFiltroCategoriaAtiva();     // Aplicar o filtro da categoria ativa
     }
 
     // Filtros de categoria
     $('#filtro-busca .btn').on('click', function () {
          const categoria = $(this).attr('id').replace('categoria-', '');
          $('#filtro-busca .active').removeClass('active');
          $(this).addClass('active');
 
          aplicarFiltroCategoriaAtiva();
     });
 
     function verificarEAtualizarAbaAtiva(data) {
          const abaAtiva = $('#filtro-busca .btn.active').attr('id');
          const totalProdutos = data.totals.totalProdutos;
          const totalPosts = data.totals.totalPosts;
          const totalFaq = data.totals.totalFaq;
 
          let maiorCategoria = 'produtos';
          let maiorTotal = totalProdutos;
 
          // Verifica qual categoria tem o maior número de itens
          if (totalPosts > maiorTotal) {
               maiorCategoria = 'posts';
               maiorTotal = totalPosts;
          }
 
          if (totalFaq > maiorTotal) {
               maiorCategoria = 'faq';
               maiorTotal = totalFaq;
          }
 
          // Verifica se a aba ativa é diferente da aba com o maior número de itens e que não seja a aba "Todos"
          if (abaAtiva !== `categoria-${maiorCategoria}`) {
               $('#filtro-busca .btn.active').removeClass('active'); // Remove a classe 'active' da aba atual
               $(`#categoria-${maiorCategoria}`).addClass('active');  // Ativa a aba com mais resultados
 
               // Atualiza o grid de resultados de acordo com a nova aba ativa
               aplicarFiltroCategoriaAtiva();
          }
     }
 
 
     function aplicarFiltroCategoriaAtiva() {
          const categoriaAtiva = $('#filtro-busca .btn.active').attr('id').replace('categoria-', '');
 
          // Atualiza a classe do grid para a aba ativa
          $('.grid-resultados').removeClass().addClass(`grid-resultados ${categoriaAtiva}`);
 
          if (categoriaAtiva === 'geral') {
               $('.item-resultado').show();
          } else {
               $('.item-resultado').hide();
               $(`.item-resultado.${categoriaAtiva}`).show();
          }
     }
 
     function atualizarContadores(data) {
          $('#n-resultado').text(data.totals.totalGeral);
          $('#total-itens-geral').text(`${data.totals.totalGeral}`);
          $('#total-itens-produtos').text(`${data.totals.totalProdutos}`);
          $('#total-itens-faq').text(`${data.totals.totalFaq}`);
          $('#total-itens-posts').text(`${data.totals.totalPosts}`);
 
          $('#categoria-produtos').attr('disabled', data.totals.totalProdutos === 0);
          $('#categoria-faq').attr('disabled', data.totals.totalFaq === 0);
          $('#categoria-posts').attr('disabled', data.totals.totalPosts === 0);
          $('#categoria-geral').attr('disabled', false);
     } 
 });
 