document.addEventListener("DOMContentLoaded", function (event) {
    $.ajax({
        url: '/core/partials/posts-recentes.php',
        method: "POST",
        dataType: 'json',
        success: function (dados) {
            for ( let post = 0; post < dados.length; post++ ) {
                $( `#post-${post}` ).addClass( 'post-categoria-' + dados[post]['slugCategoriaPost'] );
                $( `#post-${post} .categoria-link` ).attr( 'href', '/blog/categoria/' + dados[post]['slugCategoriaPost'] );
                $( `#post-${post} .categoria-link .post-categoria` ).text( dados[post]['nomeCategoriaPost'] );
                $( `#post-${post} .post-link` ).attr( 'href', '/blog/' + dados[post]['slugCategoriaPost'] + '/' + dados[post]['slugPost'] );
                $( `#post-${post} .image` ).attr( 'src', 'https://www.buscopan.com.br/assets/images/blog/posts/' + dados[post]['imagemPost'] );
                $( `#post-${post} .image` ).attr( 'alt', dados[post]['altImagemPost'] );
                $( `#post-${post} .card-img source` ).attr( 'srcset', 'https://www.buscopan.com.br/assets/images/blog/posts/' + dados[post]['imagemPost'] );
                $( `#post-${post} .post-date` ).text( dados[post]['dataPublicacaoPost'] );
                $( `#post-${post} .card-title` ).text( dados[post]['tituloPost'] );
            }
        }
    });
});  