$('.pagina-calculadora .card-escolha').click(function() {
    let peso = 0;
    let resultado = 0;
    let resultado_minimo = 0;
    let resultado_maximo = 0;
    let multiplicador = 0;
    let novo_valor = 0;
    $('#mais-peso').attr('disabled', false);
    $('#menos-peso').attr('disabled', false);
    $('#weight').val(peso);

    $('.pagina-calculadora .card-escolha').removeClass('active');
    $(this).addClass('active');
    $('.calculadora-resultado').attr('hidden', false);

    $('#weight').change(function() {
        peso = $('#weight').val();
        $('.campo-resultado').removeClass('desativado');
        $('#menos-peso').attr('disabled', false);
        
        if ( $( this ).val() >= 8 && $( '#card-calculadora-1' ).hasClass( 'active' ) )  {
            $('#mais-peso').attr('disabled', true);
            $( this ).val( 8 );
        } else if ( $( this ).val() >= 12 && $('#card-calculadora-2').hasClass('active') ) {
            $('#mais-peso').attr('disabled', true);  
            $( this ).val( 12 );
        } 

        calcularGotas(); 
    });

    if (peso === 0) {
        $('.campo-resultado').addClass('desativado');
        $('#menos-peso').attr('disabled', true);
    } else {
        $('.campo-resultado').removeClass('desativado');
        $('#menos-peso').attr('disabled', false);
    }

    if ($(this).attr('id') === 'card-calculadora-1' || $(this).attr('id') === 'card-calculadora-2' || $(this).attr('id') === 'card-calculadora-3') {
        $('.alternar-visualizacao').attr('hidden', false);
        calcularGotas();

        $('html, body').animate({
            scrollTop: $('#titulo-peso').offset().top + ($('#titulo-peso').height() - 300)
        }, 300);
    } else {
        $('.campo-resultado').removeClass('desativado');
        $('.alternar-visualizacao').attr('hidden', true);

        $('html, body').animate({
            scrollTop: $('.resultado-titulo').offset().top + ($('#titulo-peso').height() - 300)
        }, 300);
    }

    if ($(this).attr('id') === 'card-calculadora-4') {
        $('.resultado-titulo').html("De 20 a<br />40 gotas");
        $('.resultado-subtitulo').html('3 a 5 vezes ao dia');
    } else {
        $('.resultado-subtitulo').html('3 vezes ao dia');
    }
});

function alterarPeso(event) {
    if (event.data.id_botao === 'mais-peso') {
        novo_valor = parseFloat($('#weight').val()) + 0.1;
    } else {
        novo_valor = parseFloat($('#weight').val()) - 0.1;
    }

    $('#weight').val(Math.round((novo_valor * 100)) / 100);

    if ($('#weight').val() > 0) {
        $('.campo-resultado').removeClass('desativado');
        $('#menos-peso').attr('disabled', false);
    } else {
        $('.campo-resultado').addClass('desativado');
        $('#menos-peso').attr('disabled', true);
    }

    if ( $('#weight').val() >= 8 && $('#card-calculadora-1').hasClass('active') )  {
        $('#mais-peso').attr('disabled', true);
    } else if ( $('#weight').val() >= 12 && $('#card-calculadora-2').hasClass('active') ) {
        $('#mais-peso').attr('disabled', true);  
    } else {
        $('#mais-peso').attr('disabled', false);
    }

    calcularGotas();
}

function calcularGotas() {
    peso = $('#weight').val();

    if ($('.card-escolha.active').attr('id') === 'card-calculadora-1' || $('.card-escolha.active').attr('id') === 'card-calculadora-2') {
        if ($('.card-escolha.active').attr('id') === 'card-calculadora-1') {
            multiplicador = '3';
        } else if ($('.card-escolha.active').attr('id') === 'card-calculadora-2') {
            multiplicador = '1.4';
        }

        resultado = Math.round((peso * multiplicador).toFixed(0));
        $('.resultado-titulo').html(resultado + " gotas");
    } else if ($('.card-escolha.active').attr('id') === 'card-calculadora-3') {
        resultado = Math.round((peso * 1).toFixed(0));
        $('.resultado-titulo').html(resultado + " gotas");
    }
}

$('#mais-peso').click({ id_botao: 'mais-peso' }, alterarPeso);
$('#menos-peso').click({ id_botao: 'menos-peso' }, alterarPeso);

// $('#weight').change(function() {
//     if ( $('#weight').val() >= 8 && $('#card-calculadora-1').hasClass('active') ) {
//         $('#weight').val(8);
//     } else if ( $('#weight').val() >= 12 && $('#card-calculadora-2').hasClass('active') ) {
//         $('#weight').val(12);
//     }
// });