// Dados Estruturados - Criação do schema FAQPage
let lista_faq_post = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": []
}
let titulos = document.querySelectorAll('.texto-post h2, .texto-post h3, .texto-post h4, .texto-post h5, .texto-post h6');
titulos.forEach(titulo => {
    if (titulo.textContent.indexOf('?') > -1) {
        let question = {
            "@type": "Question",
            "name": titulo.textContent,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": titulo.nextElementSibling.textContent
            }
        }

        lista_faq_post.mainEntity.push(question);
    }
})
let seoElement = document.getElementById('seo');
if (seoElement) {
    let newScript = document.createElement('script');
    seoElement.append(newScript);
    newScript.setAttribute('type', 'application/ld+json');
    newScript.innerHTML = JSON.stringify(lista_faq_post);
}

// Criação do Sumário
function removerAcentos(texto) {
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
let lista_titulos = {}
titulos.forEach(titulo => {
    let id = removerAcentos(titulo.textContent).replace(/[^\w\s]/g, "").replace(/\s+/g, "-").toLowerCase();
    titulo.setAttribute('id', id);
    lista_titulos[titulo.textContent] = id;
})
let sumario = document.createElement('ul');
sumario.setAttribute('id', 'sumario-post');
sumario.className = 'sumario-post mt-5';
if(document.querySelector('.conteudo-post .imagem-post') ) {
    document.querySelector('.conteudo-post .imagem-post').append( sumario );
}
Object.keys(lista_titulos).forEach(titulo => {
    let li = document.createElement('li');
    li.className = 'mb-1 fs-5';
    let a = document.createElement('a');
    a.setAttribute('href', '#' + lista_titulos[titulo]);
    a.textContent = titulo;
    li.append(a);
    sumario.append(li);
})			

// Lazy loading de imagens de background (data-bg)
document.addEventListener("DOMContentLoaded", function () {
    const lazyBackgrounds = document.querySelectorAll(".imagem-lazy-loading");
    
    const lazyLoad = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const bgImage = entry.target.getAttribute('data-bg');
                const bgImageMobile = entry.target.getAttribute('data-bg-mobile');
                if (window.matchMedia("(max-width: 991.98px)").matches) {
                    entry.target.style.backgroundImage = `url(${bgImageMobile})`;
                } else {
                    entry.target.style.backgroundImage = `url(${bgImage})`;
                }
                entry.target.classList.add('lazy-loading-carregado');
                observer.unobserve(entry.target);
            }
        });
    };
    
    const observer = new IntersectionObserver(lazyLoad, {
        root: null,
        rootMargin: "0px",
        threshold: 0.1
    });
    
    lazyBackgrounds.forEach(lazyBackground => {
        observer.observe(lazyBackground);
    });
});