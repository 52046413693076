// Criação do componente
var closeButtonExternal = document.createElement("button");
closeButtonExternal.type = "button";
closeButtonExternal.dataset.bsDismiss = "alert";
closeButtonExternal.ariaLabel = "Close";
closeButtonExternal.className = "abrir-banner-fixo d-none";
closeButtonExternal.style.display = "none";
closeButtonExternal.innerHTML = '<span aria-hidden="true"><span class="material-symbols-outlined">mail</span></span>';

var bannerDiv = document.createElement("div");
bannerDiv.className = "banner-fixo p-3";
bannerDiv.style.display = "block";

var closeButtonInternal = document.createElement("button");
closeButtonInternal.className = "fechar-banner-fixo";
closeButtonInternal.type = "button";
closeButtonInternal.dataset.bsDismiss = "alert";
closeButtonInternal.ariaLabel = "Close";
closeButtonInternal.innerHTML = '<span aria-hidden="true"><span class="material-symbols-outlined">expand_more</span></span>';

bannerDiv.appendChild(closeButtonInternal);

var containerDiv = document.createElement("div");
containerDiv.className = "container";
bannerDiv.appendChild(containerDiv);

var row = document.createElement("div");
row.className = "row my-2 row-gap-3";
containerDiv.appendChild(row);

var col1_1 = document.createElement("div");
col1_1.className = "col-md-4 col-lg-6 col-xl-4 form";
col1_1.innerHTML = '<p class="banner-fixo-orientacao h5">Cadastre-se e garanta 20% OFF*</p><p class="banner-fixo-orientacao mb-0">*Válido para primeira compra acima de R$50,00</p>';
row.appendChild(col1_1);

var nameInput = document.createElement("input");
nameInput.className = "form-control";
nameInput.id = "inputNameBannerFixo";
nameInput.type = "text";
nameInput.name = "nome";
nameInput.placeholder = "Digite seu nome";
col1_1.appendChild(nameInput);

var col1_2_2 = document.createElement("form");
col1_2_2.className = "col-md-4 col-lg-6 col-xl-4 form d-flex align-items-end";
row.appendChild(col1_2_2);

var emailInput = document.createElement("input");
emailInput.className = "form-control";
emailInput.id = "inputEmailBannerFixo";
emailInput.type = "email";
emailInput.name = "email";
emailInput.placeholder = "Digite seu email";
col1_2_2.appendChild(emailInput);

var col1_3 = document.createElement("div");
col1_3.className = "col-md-4 col-lg-12 col-xl-4 d-flex justify-content-center align-items-end";
row.appendChild(col1_3);

var subscribeButton = document.createElement("button");
subscribeButton.className = "btn btn-primary w-100 btn-banner-fixo";
subscribeButton.type = "button";
subscribeButton.id = "btnCheck";
subscribeButton.innerHTML = '<span class="btn-conteudo"><span class="btn-icone"><span class="material-symbols-outlined">radio_button_unchecked</span></span><span class="btn-texto">Aceito receber e-mails e concordo com a <a class="text-decoration-underline" href="/politica-de-privacidade">Política de Privacidade.</a></span></span><div class="progress-bar" style="width: 0%; transition: width 5s linear 0s;"></div>';
col1_3.appendChild(subscribeButton);

// Adiciona o banner ao corpo do documento
document.body.appendChild(closeButtonExternal);
document.body.appendChild(bannerDiv);

const fecharPopupFixo = document.querySelector('.fechar-banner-fixo');
const abrirPopupFixo = document.querySelector('.abrir-banner-fixo');
const bannerFixo = document.querySelector('.banner-fixo');

if (fecharPopupFixo) {
    fecharPopupFixo.addEventListener('click', function() {
        bannerFixo.classList.add('d-none');
        bannerFixo.style.display = 'none';
        abrirPopupFixo.classList.remove('d-none');
        abrirPopupFixo.style.display = 'block';
        sessionStorage.setItem("crm_popup", "true");
    });
}

function fechaBanner() {
    setTimeout(() => {
        bannerFixo.classList.add('d-none');
        bannerFixo.style.display = 'none';
        abrirPopupFixo.classList.remove('d-none');
        abrirPopupFixo.style.display = 'block';
        sessionStorage.setItem("crm_popup", "true");
    }, 2000);
}

abrirPopupFixo.addEventListener('click', function() {
    bannerFixo.classList.remove('d-none');
    bannerFixo.style.display = 'block';
    abrirPopupFixo.classList.add('d-none');
    abrirPopupFixo.style.display = 'none';
});

validacaoEmail = false;
validacaoNome = false;

subscribeButton.addEventListener('click', function() {
    if(!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(emailInput.value) || emailInput.value === '') {
        emailInput.classList.add("alerta", "alert", "alert-danger");
        emailInput.placeholder = "Digite um e-mail válido.";
        validacaoEmail = false;
    }
    
    if (nameInput.value === '') {
        nameInput.classList.add("alerta", "alert", "alert-danger");
        nameInput.placeholder = "Campo obrigatório.";
        nameInput.focus();
        validacaoNome = false;
    } else {
        nameInput.classList.remove("alerta", "alert", "alert-danger");
        nameInput.placeholder = "Digite seu nome";
        validacaoNome = true;

        if(emailInput.value !== '') {
            emailInput.classList.remove("alerta", "alert", "alert-danger");
            emailInput.placeholder = "Digite seu email";
            validacaoEmail = true;
        }
    }

    console.log(validacaoEmail, validacaoNome, emailInput.value, nameInput.value);

    if (validacaoEmail && validacaoNome) {
        const data = new FormData();
        data.append('nome', nameInput.value);
        data.append('email', emailInput.value);
        data.append('componente', 'banner-fixo');
        data.append('origem', document.getElementById("origem-crm").dataset.origem);
        data.append('url', window.location.href);
        fetch("/salva-contato.php", {
            method: "POST",
            mode: 'cors',
            body: data
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));
        /* Fim do envio do email */
        // Inicia a animação da barra de progresso
        sessionStorage.setItem("crm_popup", "true");
        sessionStorage.setItem("crm_banner_fixo", "true");
        const progressBar = document.querySelector('.progress-bar');
        progressBar.style.width = '100%';
        progressBar.style.transition = 'width 1s linear';
        envio = setTimeout(() => {
            const btnConteudo = document.querySelector('.btn-conteudo');
            btnConteudo.innerHTML = '<span class="btn-icone"><span class="material-symbols-outlined"> mark_email_read </span></span> <span class="btn-texto">E-mail cadastrado! Em breve você receberá novidades!</span>';
            fechaBanner();
            emailInput.value = ''; // Limpa o valor do input de e-mail
            emailInput.classList.remove("alerta");
            emailInput.placeholder = "Digite seu email";
        }, "1005");
        const cookieBanner = document.querySelector('#cookie-banner');
        if(cookieBanner){
            cookieBanner.classList.add('no-banner-fixo-show');
        }
    }
});

//Verifica se o cadastro já foi realizado
if(sessionStorage.getItem("crm_banner_fixo") == "true"){
    sessionStorage.setItem( 'cadastrou_crm_popup_prisma', 'true' );
    fechaBanner();
    const cookieBanner = document.querySelector('#cookie-banner');
    if(cookieBanner){
        cookieBanner.classList.add('no-banner-fixo-show');
    }
    const bannerFixoElementos = document.querySelectorAll('.banner-fixo .row');
    bannerFixoElementos.forEach(elemento => {
        elemento.remove();
    });
    containerDiv.innerHTML = '<p id="crm-banner-fixo-titulo" class="d-flex text-center text-white fw-bold d-flex justify-content-center align-items-center gap-3"><span class="material-symbols-outlined"> mark_email_read </span>E-mail cadastrado!</p><p id="crm-banner-fixo-descricao" class="text-center text-white fw-light fs-6">Em breve você receberá novidades e ofertas exclusivas, fique ligado!</p>';
} else {
    closeButtonExternal.classList.add('com-notificacao');
}