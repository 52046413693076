// import $ from 'jquery';
// import 'sweetalert2/dist/sweetalert2.all.min.js';
import 'bootstrap/js/dist/modal';

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

//Captura de Dados - PopUp
$("#crm-btn-popup").on("click", function () {
    url = window.location.href;
    componente = $(this).data("componente");
    origem = $(this).data("origem");
    nome = $("#crm-nome-popup").val();
    email = $("#crm-email-popup").val();

    if ($("#crm-nome-popup").is(":required")) {
        document.getElementById("crm-aviso-nome-popup").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-nome-popup")
            .classList.add("exibe-aviso");
    }
    if (email != "") {
        if (validateEmail(email)) {
            enviaDadosLead(nome, email, origem, componente, url);
        } else {
            document.getElementById("crm-aviso-email-popup").innerHTML =
                "*Digite um e-mail vÃ¡lido.";
            document
                .getElementById("crm-aviso-email-popup")
                .classList.add("exibe-aviso");
        }
    } else {
        document.getElementById("crm-aviso-email-popup").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-email-popup")
            .classList.add("exibe-aviso");
    }
});

//Captura de Dados - PopUp no Header
// $("#crm-btn-popup-menu").on("click", function () {
//     url = window.location.href;
//     componente = $(this).data("componente");
//     origem = $(this).data("origem");
//     nome = $("#crm-nome-popup-menu").val();
//     email = $("#crm-email-popup-menu").val();

//     if ($("#crm-nome-popup-menu").is(":required")) {
//         document.getElementById("crm-aviso-nome-popup-menu").innerHTML =
//             "*Preenchimento obrigatório.";
//         document
//             .getElementById("crm-aviso-nome-popup-menu")
//             .classList.add("exibe-aviso");
//     }
//     if (email != "") {
//         if (validateEmail(email)) {
//             enviaDadosLead(nome, email, origem, componente, url);
//             $("#crm-popup-menu").hide();
//             $(".modal-backdrop").hide();
//             $("body").removeClass("modal-open").css("overflow", "scroll");
//             $("#crm-email-popup-menu").val('');
//             $("#crm-nome-popup-menu").val('');
//         } else {
//             document.getElementById("crm-aviso-email-popup-menu").innerHTML =
//                 "*Digite um e-mail vÃ¡lido.";
//             document
//                 .getElementById("crm-aviso-email-popup-menu")
//                 .classList.add("exibe-aviso");
//         }
//     } else {
//         document.getElementById("crm-aviso-email-popup-menu").innerHTML =
//             "*Preenchimento obrigatório.";
//         document
//             .getElementById("crm-aviso-email-popup-menu")
//             .classList.add("exibe-aviso");
//     }
// });

//Captura de Dados - Header
$("#crm-btn-header").on("click", function () {
    url = window.location.href;
    componente = $(this).data("componente");
    origem = $(this).data("origem");
    nome = $("#crm-nome-header").val();
    email = $("#crm-email-header").val();

    if ( $("#crm-nome-header").length && $("#crm-nome-header").is(":required") ) {
        document.getElementById("crm-aviso-nome-header").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-nome-header")
            .classList.add("exibe-aviso");
    }
    if (email != "") {
        if (validateEmail(email)) {
            enviaDadosLead(nome, email, origem, componente, url);
        } else {
            document.getElementById("crm-aviso-email-header").innerHTML =
                "*Digite um e-mail vÃ¡lido.";
            document
                .getElementById("crm-aviso-email-header")
                .classList.add("exibe-aviso");
        }
    } else {
        document.getElementById("crm-aviso-email-header").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-email-header")
            .classList.add("exibe-aviso");
    }
});

//Captura de Dados - Footer
$("#crm-btn-footer").on("click", function () {
    url = window.location.href;
    componente = $(this).data("componente");
    origem = $(this).data("origem");
    nome = $("#crm-nome-footer").val();
    email = $("#crm-email-footer").val();

    if ( $("#crm-nome-footer").length && $("#crm-nome-footer").is(":required") ) {
        document.getElementById("crm-aviso-nome-footer").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-nome-footer")
            .classList.add("exibe-aviso");
    }
    if (email != "") {
        if (validateEmail(email)) {
            enviaDadosLead(nome, email, origem, componente, url);
        } else {
            document.getElementById("crm-aviso-email-footer").innerHTML =
                "*Digite um e-mail vÃ¡lido.";
            document
                .getElementById("crm-aviso-email-footer")
                .classList.add("exibe-aviso");
        }
    } else {
        document.getElementById("crm-aviso-email-footer").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-email-footer")
            .classList.add("exibe-aviso");
    }
});

//Captura de Dados - Blog (Componente Content)
$("#crm-btn-blog").on("click", function () {
    url = window.location.href;
    componente = $(this).data("componente");
    origem = $(this).data("origem");
    nome = $("#crm-nome-blog").val();
    email = $("#crm-email-blog").val();

    if ( $("#crm-nome-blog").length && $("#crm-nome-blog").is(":required") ) {
        document.getElementById("crm-aviso-nome-blog").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-nome-blog")
            .classList.add("exibe-aviso");
    }
    if (email != "") {
        if (validateEmail(email)) {
            enviaDadosLead(nome, email, origem, componente, url);
        } else {
            document.getElementById("crm-aviso-email-blog").innerHTML =
                "*Digite um e-mail vÃ¡lido.";
            document
                .getElementById("crm-aviso-email-blog")
                .classList.add("exibe-aviso");
        }
    } else {
        document.getElementById("crm-aviso-email-blog").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-email-blog")
            .classList.add("exibe-aviso");
    }
});

//Captura de Dados - Quiz (Componente Content)
$("#btn-quiz-avancar-resultado").on("click", function () {
    url = window.location.href;
    componente = $(this).data("componente");
    origem = $(this).data("origem");
    nome = $("#crm-quiz-nome").val();
    email = $("#crm-quiz-email").val();

    if ( $("#crm-quiz-nome").length && $("#crm-quiz-nome").is(":required") ) {
        document.getElementById("crm-aviso-nome-quiz").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-nome-quiz")
            .classList.add("exibe-aviso");
    }
    if (email != "") {
        if (validateEmail(email)) {
            enviaDadosLead(nome, email, origem, componente, url);
        } else {
            document.getElementById("crm-aviso-email-quiz").innerHTML =
                "*Digite um e-mail vÃ¡lido.";
            document
                .getElementById("crm-aviso-email-quiz")
                .classList.add("exibe-aviso");
        }
    } else {
        document.getElementById("crm-aviso-email-quiz").innerHTML =
            "*Preenchimento obrigatório.";
        document
            .getElementById("crm-aviso-email-quiz")
            .classList.add("exibe-aviso");
    }
});

$("#crm-email-popup").on("keypress", function () {
    document
        .getElementById("crm-aviso-email-popup")
        .classList.remove("exibe-aviso");
});

$("#crm-email-header").on("keypress", function () {
    document
        .getElementById("crm-aviso-email-header")
        .classList.remove("exibe-aviso");
});

$("#crm-email-footer").on("keypress", function () {
    document
        .getElementById("crm-aviso-email-footer")
        .classList.remove("exibe-aviso");
});

$("#crm-email-blog").on("keypress", function () {
    document
        .getElementById("crm-aviso-email-blog")
        .classList.remove("exibe-aviso");
});

$("#btn-quiz-avancar-resultado").on("keypress", function () {
    document
        .getElementById("crm-aviso-email-quiz")
        .classList.remove("exibe-aviso");
});


//Envio dos Dados
function enviaDadosLead(nome, email, origem, componente, url) {
    console.log("Dados enviados.", email, origem);

    $.ajax({
        url: "https://api.hypera.com.br/crm/salva-contato.php", // URL em que serÃ¡ feita a requisiÃ§Ã£o
        method: "POST", // MÃ©todo da RequisiÃ§Ã£o (GET ou POST)
        dataType: "json", // Formato da resposta esperada
        data: {
            // Dados que serÃ£o enviados
            email: email,
            nome: nome,
            componente: componente,
            origem: origem,
            url: btoa(url),
        },
        success: function (data) {
            // Em caso de sucesso, os dados sÃ£o o que retornou no sucesso
            if (data.status == 'success') {
                if( document.getElementById("crm-aviso-email-popup") != null ) {
                    document.getElementById("crm-aviso-email-popup").classList.remove("exibe-aviso");
                }
                if( document.getElementById("crm-aviso-email-header") != null ) {
                    document.getElementById("crm-aviso-email-header").classList.remove("exibe-aviso");
                }
                if( document.getElementById("crm-aviso-email-footer") != null ) {
                    document.getElementById("crm-aviso-email-footer").classList.remove("exibe-aviso");
                }
                $("#crm-email-popup").val('');
                $("#crm-nome-popup").val('');
                $("#crm-email-header").val('');
                $("#crm-nome-header").val('');
                $("#crm-email-footer").val('');
                $("#crm-nome-footer").val('');
                Swal.fire({
                    // CADASTRO CONCLUIDO COM SUCESSO
                    title: "Sucesso :)", // TITULO DO POP-UP
                    text: "Seu cadastro foi concluÃ­do e em breve vocÃª receberÃ¡ nossas comunicaÃ§Ãµes!", // TEXTO INSERIDO NO POP-UP
                    icon: "success", // LOGO DO POP-UP (SUCESSO)
                    confirmButtonText: "OKAY! CONTINUE.", // BOTAO DE ENCERRAMENTO
                });

                if (nome.attr("id") === "name-crm-popup" || nome.attr("id") === "crm-nome-popup") {
                    $("#formulario-cadastro-popup").hide();
                    $(".modal-backdrop").hide();
                    $("body")
                        .removeClass("modal-open")
                        .css("overflow", "scroll");
                    sessionStorage.setItem("crm_popup", "true");
                }
            } else {
                if (data.servico == "contato-existente") {
                    Swal.fire({
                        title: "Olha sÃ³...", // TITULO DO POP-UP
                        text: "VocÃª jÃ¡ se cadastrou em nossa newsletter. Aguarde para receber nossas comunicaÃ§Ãµes!", // TEXTO INSERIDO NO POP-UP
                        icon: "warning", // LOGO DO POP-UP (ATENÃ‡ÃƒO)
                        confirmButtonText: "Tudo bem, EntÃ£o!", // BOTAO DE ENCERRAMENTO
                    });
                } else {
                    Swal.fire({
                        title: "Olha sÃ³...", // TITULO DO POP-UP
                        text: "VocÃª jÃ¡ se cadastrou em nossa newsletter. Aguarde para receber nossas comunicaÃ§Ãµes!", // TEXTO INSERIDO NO POP-UP
                        icon: "info", // LOGO DO POP-UP (INFORMAÃ‡ÃƒO)
                        confirmButtonText: "Tudo bem, EntÃ£o!", // BOTAO DE ENCERRAMENTO
                    });
                }
            }
        },
        error: function (request) {
            if (request) {
                Swal.fire({
                    title: "Ops... :(", // TITULO DO POP-UP
                    text: "NÃ£o foi possÃ­vel te cadastrar. Tente novamente mais tarde.", // TEXTO INSERIDO NO POP-UP
                    icon: "error", // LOGO DO POP-UP (ERRO)
                    confirmButtonText: "OKAY! VOLTAREI DEPOIS.", // BOTAO DE ENCERRAMENTO
                });
            }
        },
    });
}