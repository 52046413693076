import $ from 'jquery';
import 'bootstrap/js/dist/modal';

window.$ = window.jQuery = $;

document.addEventListener('DOMContentLoaded', function () {
    var siteurl = window.location.protocol + "//" + window.location.host;
    var sitepath = siteurl + window.location.pathname;

    let alternarFiltro = (event) => {
        $('.filtro-abas .active').removeClass('active');
        $(event.target).addClass('active');
    };
    $( '.filtro-abas .item-texto' ).click( alternarFiltro );

    // Query padrão de posts (Load More e Categorias)
    let posts_por_carregamento;
    let categoria_post = '*';
    let total_posts_carregadas = 9;
    let autor = null;

    let ajaxQueryPosts = (categoria_post, total_posts_carregadas, autor = null) => {
        posts_por_carregamento = 8;
        total_posts_carregadas += posts_por_carregamento;

        $.ajax({
            url: siteurl + '/core/partials/query-ajax-posts.php',
            method: 'POST',
            async: false,
            dataType: 'html',
            data: {
                numero_posts: total_posts,
                posts_por_carregamento: total_posts_carregadas,
                categoria_post: categoria_post,
                autor: autor
            },
            success: function(data) {
                $('#carregar-posts').html(data.split('</article>'));
                let posts_restantes_por_categoria = $('#posts-restantes').val();

                if (posts_restantes_por_categoria === '0') {
                    $('#carregar-posts').append('<p>Não há mais posts.</p>');
                }

                esconderBotaoCarregarMaisPosts(posts_restantes_por_categoria, total_posts_carregadas);
            }
        })
    };

    const esconderBotaoCarregarMaisPosts = (posts_restantes_por_categoria, total_posts_carregadas) => {
        if (posts_restantes_por_categoria <= total_posts_carregadas) {
            $('#carregar-mais-posts').prop('disabled', true).text('Todas os posts foram carregados.');
        } else {
            $('#carregar-mais-posts').prop('disabled', false).text('Carregar mais').append('<span class="visually-hidden" aria-hidden="true"> posts</span>');
        }
    }

    $('#carregar-mais-posts').click(function() {
        $('#carregar-posts').addClass('grid-carregado');
        $('#grid-posts').addClass('grid-carregado');
        let posts_por_carregamento = 8;
        total_posts_carregadas += posts_por_carregamento;

        if( window.location.href.split('/')[4] === 'categoria' ) {
            categoria_post = window.location.href.split('/')[5];
            ajaxQueryPosts(categoria_post, total_posts_carregadas);
        } else if( window.location.href.split('/')[4] === 'autor' ) {
            posts_por_carregamento = 9;
            autor = window.location.href.split('/')[5];
            ajaxQueryPosts('*', total_posts_carregadas, autor);
        } else {
            categoria_post = '*';
            ajaxQueryPosts(categoria_post, total_posts_carregadas);
        } 
    });
});