// import $ from 'jquery';
// import 'sweetalert2/dist/sweetalert2.all.min.js';
import 'bootstrap/js/dist/modal';

// window.$ = $;
// window.jQuery = $;

const origem = document.getElementById("crm-popup-menu-componente").dataset.origem;

// Validadores
function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

// Criação do botão de abrir/fechar popup
var buttonToggleCartinha = document.createElement("button");
buttonToggleCartinha.type = "button";
buttonToggleCartinha.dataset.bsToggle = "modal";
buttonToggleCartinha.dataset.bsTarget = "#crm-popup-menu";
buttonToggleCartinha.id = "btn-abrir-crm-popup-menu";
buttonToggleCartinha.className = "btn btn-transparent btn-crm-menu com-notificacao";
buttonToggleCartinha.innerHTML = '<span class="material-symbols-rounded position-relative">mail</span>';
buttonToggleCartinha.setAttribute("aria-haspopup", "true");
buttonToggleCartinha.setAttribute("aria-controls", "popup");
buttonToggleCartinha.setAttribute("aria-expanded", "false");

// Criação do div principal do banner
var popupDivCartinha = document.createElement("div");
popupDivCartinha.id = "crm-popup-menu";
popupDivCartinha.className = "modal fade";
popupDivCartinha.tabindex = "-1";
popupDivCartinha.ariaLabelledby = "crm-popup-menu-titulo";
popupDivCartinha.ariaHidden = "true";
popupDivCartinha.dataset.bsBackdrop = "false";
popupDivCartinha.role = "dialog";

// Criação da Div de nivel 1 interna
var divPopup1Cartinha = document.createElement("div");
divPopup1Cartinha.className = "modal-dialog m-0";
popupDivCartinha.appendChild(divPopup1Cartinha);
popupDivCartinha.setAttribute("aria-labelledby", "crm-popup-menu-titulo");
popupDivCartinha.setAttribute("role", "dialog");

// Criação da Div de nivel 2 interna
var divPopup2 = document.createElement("div");
divPopup2.className = "modal-content border-0";
divPopup1Cartinha.appendChild(divPopup2);

// Criação da Header do popup interno
var divHeaderCartinha = document.createElement("div");
divHeaderCartinha.className = "modal-header container border-0 p-0 pt-3 pe-3";
divPopup2.appendChild(divHeaderCartinha);

// Criação da botão de fechar dentro do Header do popup
var btnCloseCartinha = document.createElement("button");
btnCloseCartinha.type = "button";
btnCloseCartinha.id = "btn-fechar-crm-popup-menu";
btnCloseCartinha.className = "btn-close";
btnCloseCartinha.dataset.bsDismiss = "modal";
btnCloseCartinha.ariaLabel = "Close"
divHeaderCartinha.appendChild(btnCloseCartinha);

// Criação do Body do popup interno
var divBodyCartinha = document.createElement("div");
divBodyCartinha.className = "modal-body p-3";
divPopup2.appendChild(divBodyCartinha);

// Criação do Form dentro do Body do popup
var divFormCartinha = document.createElement("form");
divFormCartinha.className = "form form-inline";
divBodyCartinha.appendChild(divFormCartinha);

// Criação do tÃ­tulo do Form dentro popup
var divTitleCartinha = document.createElement("p");
divTitleCartinha.id = "crm-popup-menu-titulo";
divTitleCartinha.className = "text-center fw-bold";
divTitleCartinha.textContent = "Cadastre-se e garanta 20% OFF*";    
divFormCartinha.appendChild(divTitleCartinha);

// Criação da descriÃ§Ã£o do Form dentro popup
var divDescricaoCartinha = document.createElement("p");
divDescricaoCartinha.id = "crm-popup-menu-descricao";
divDescricaoCartinha.className = "text-center fw-light fs-6";
divDescricaoCartinha.textContent = "*Válido para primeira compra acima de R$50,00";    
divFormCartinha.appendChild(divDescricaoCartinha);

// Criação da primeira linha do Form dentro popup
var divGrupo2Cartinha = document.createElement("div");
divGrupo2Cartinha.className = "form-group mb-3";
divFormCartinha.appendChild(divGrupo2Cartinha);

// Criação da Label dentro primeira linha do Form
var nomeLabelCartinha = document.createElement("label");
nomeLabelCartinha.className = "visually-hidden-focusable";
nomeLabelCartinha.ariaHidden = "true";
nomeLabelCartinha.for = "crm-nome-popup-menu";
nomeLabelCartinha.textContent = "Digite seu nome";    
divGrupo2Cartinha.appendChild(nomeLabelCartinha);

// Criação do Input dentro primeira linha do Form
var nomeInputCartinha = document.createElement("input");
nomeInputCartinha.id = "crm-nome-popup-menu";
nomeInputCartinha.name = "nome-crm";
nomeInputCartinha.className = "form-control nome";
nomeInputCartinha.type = "text";  
nomeInputCartinha.placeholder = "Digite seu nome";  
divGrupo2Cartinha.appendChild(nomeInputCartinha);

// Criação do alerta do Input dentro primeira linha do Form
var nomeAlertaCartinha = document.createElement("small");
nomeAlertaCartinha.id = "crm-aviso-nome-popup-menu";
nomeAlertaCartinha.className = "form-text fw-bold crm-aviso"; 
divGrupo2Cartinha.appendChild(nomeAlertaCartinha);

// Criação da segunda linha do Form dentro popup
var divGrupo3Cartinha = document.createElement("div");
divGrupo3Cartinha.className = "form-group mb-3";
divFormCartinha.appendChild(divGrupo3Cartinha);

// Criação da Label dentro segunda linha do Form
var emailLabelCartinha = document.createElement("label");
emailLabelCartinha.className = "visually-hidden-focusable";
emailLabelCartinha.ariaHidden = "true";
emailLabelCartinha.for = "crm-email-popup-menu";
emailLabelCartinha.textContent = "Digite seu e-mail";    
divGrupo3Cartinha.appendChild(emailLabelCartinha);

// Criação do Input dentro segunda linha do Form
var emailInputCartinha = document.createElement("input");
emailInputCartinha.id = "crm-email-popup-menu";
emailInputCartinha.name = "email-crm";
emailInputCartinha.className = "form-control email";
emailInputCartinha.type = "email";  
emailInputCartinha.placeholder = "Digite seu e-mail";  
divGrupo3Cartinha.appendChild(emailInputCartinha);

// Criação do alerta do Input dentro segunda linha do Form
var emailAlertaCartinha = document.createElement("small");
emailAlertaCartinha.id = "crm-aviso-email-popup-menu";
emailAlertaCartinha.className = "form-text fw-bold crm-aviso"; 
divGrupo3Cartinha.appendChild(emailAlertaCartinha);

// Criação do botão de envio/aceite do Form
var subscribeButtonCartinha = document.createElement("button");
subscribeButtonCartinha.id = "crm-btn-popup-menu";
subscribeButtonCartinha.className = "btn btn-popup-menu mb-3 w-100 position-relative overflow-hidden border-0";
subscribeButtonCartinha.type = "button";
subscribeButtonCartinha.innerHTML = '<span class="btn-conteudo d-flex flex-row align-content-center justify-content-center align-items-center flex-nowrap"><span class="btn-icone me-1"><span class="material-symbols-outlined">radio_button_unchecked</span></span><span class="btn-texto text-left fs-7">Aceito receber e-mails e concordo com a <a class="text-decoration-underline fw-bold" href=\"/politica-privacidade\" target=\"_blank\" rel=\"noopener\">Política de Privacidade.</a></span></span><div class="progress-bar position-absolute" style="width: 0%; transition: width 5s linear 0s;"></div>';
divFormCartinha.appendChild(subscribeButtonCartinha);

// Adiciona o botão e o popup ao corpo do documento
let divLocationCartinha = document.getElementById("crm-popup-menu-componente");
divLocationCartinha.classList.add("position-relative");
divLocationCartinha.appendChild(buttonToggleCartinha);
divLocationCartinha.appendChild(popupDivCartinha);

// Acessibilidade - Abrir e fechar modal
document.getElementById('btn-abrir-crm-popup-menu').addEventListener('click', function () {
    var popup = document.getElementById('crm-popup-menu');
    if( popup.classList.contains('show') ) {
        popup.setAttribute('aria-hidden', 'false');
        this.setAttribute('aria-expanded', 'true');
        popup.querySelector('input').focus();
    } else {
        popup.setAttribute('aria-hidden', 'true');
        this.setAttribute('aria-expanded', 'false');
    }
})

document.getElementById('btn-fechar-crm-popup-menu').addEventListener('click', function () {
    var popup = document.getElementById('crm-popup-menu');
    popup.setAttribute('aria-hidden', 'true');
    document.getElementById('btn-abrir-crm-popup-menu').setAttribute('aria-expanded', 'false');
    document.getElementById('btn-abrir-crm-popup-menu').focus();
})

// Captura de Dados - Popup Menu (sem Prisma)
document.getElementById("crm-btn-popup-menu").addEventListener('click', () => {
    let validacaoEmail = false;
    let validacaoNome = false;

    if ( document.getElementById( "crm-email-popup-menu" ).value ) {
        if (validateEmail( document.getElementById( "crm-email-popup-menu" ).value )) {
            validacaoEmail = true;
            emailInputCartinha.classList.remove("alerta");
            document.getElementById("crm-aviso-email-popup-menu").innerHTML = "";
            document.getElementById("crm-aviso-email-popup-menu").classList.remove("exibe-aviso");
        } else {
            validacaoEmail = false;
            emailInputCartinha.classList.add("alerta");
            document.getElementById("crm-aviso-email-popup-menu").innerHTML = "*Digite um e-mail válido.";
            document.getElementById("crm-aviso-email-popup-menu").classList.add("exibe-aviso");
        }
    } else {
        validacaoEmail = false;
        emailInputCartinha.classList.add("alerta");
        emailInputCartinha.placeholder = "O e-mail é obrigatório.";
        emailInputCartinha.focus();
    }

    if ( document.getElementById( "crm-nome-popup-menu" ).value == '' ) {
        validacaoNome = false;
        nomeInputCartinha.classList.add("alerta");
        nomeInputCartinha.placeholder = "O nome é obrigatório.";
        nomeInputCartinha.focus();
    } else {
        validacaoNome = true;
        nomeInputCartinha.classList.remove("alerta");
        document.getElementById("crm-aviso-nome-popup-menu").innerHTML = "";
        document.getElementById("crm-aviso-nome-popup-menu").classList.remove("exibe-aviso");
    }

    if (document.getElementById("crm-btn-popup-menu").hasAttribute('checked')) {
        document.getElementById("crm-btn-popup-menu").removeAttribute('checked');
        document.querySelector('#crm-popup-menu .btn-icone').innerHTML = '<span class="material-symbols-outlined"> radio_button_unchecked </span>';
        // Inicia a animação da barra de progresso
        const progressBarCartinha = document.querySelector('#crm-popup-menu .progress-bar');
        progressBarCartinha.style.width = '0%';
        progressBarCartinha.style.transition = 'width 1s linear';
        clearTimeout(envioCartinha);
    } else {
        if( validacaoEmail && validacaoNome ) {
            const url = btoa(window.location.href);
            const nome = document.getElementById("crm-nome-popup-menu").value;
            const email = document.getElementById("crm-email-popup-menu").value;

            var data = new FormData();
            data.append("nome",nome);
            data.append("email",email);
            data.append("componente","popup-menu");
            data.append("origem",origem);
            data.append("url",url);
            fetch("/salva-contato.php", {
                method: "POST",
                mode: 'cors',
                body: data
            })
            .then(response => response.json())
            .then(data => {
                if (data.servico && data.servico.contato === "contato-existente") {
                    Swal.fire({
                        title: "Olha só...", // TITULO DO POP-UP
                        text: "Você já se cadastrou em nossa newsletter. Aguarde para receber nossas comunicações!", // TEXTO INSERIDO NO POP-UP
                        icon: "warning", // LOGO DO POP-UP (ATENÇÃO)
                        confirmButtonText: "Tudo bem, Então!", // BOTAO DE ENCERRAMENTO
                    });
                } else {
                    // Inicia a animação da barra de progresso
                    const progressBarCartinha = document.querySelector('#crm-popup-menu .progress-bar');
                    progressBarCartinha.style.width = '100%';
                    progressBarCartinha.style.transition = 'width 1s linear';
                    envioCartinha = setTimeout(() => {
                        document.querySelector('#btn-abrir-crm-popup-menu').classList.remove('com-notificacao');
                        document.querySelector('#crm-popup-menu-titulo').innerHTML = '<span class="material-symbols-outlined"> mark_email_read </span>E-mail cadastrado!';
                        document.querySelector('#crm-popup-menu-titulo').classList.add("d-flex", "justify-content-center", "align-items-center", "gap-3");
                        document.querySelector('#crm-popup-menu-descricao').innerHTML = 'Em breve você receberá novidades e ofertas exclusivas, fique ligado!';
                        document.querySelectorAll('#crm-popup-menu .form-group').forEach(elemento => {
                            elemento.classList.add('d-none');
                        })
                        document.querySelector('#crm-btn-popup-menu').classList.add('d-none'); 

                        setTimeout(() => {
                            // Reseta os estilos, sem o popup
                            document.getElementById("crm-popup-menu").style.display = "none";
                            document.body.classList.remove("modal-open");
                            document.body.style.overflow = "scroll";
                            sessionStorage.setItem( 'cadastrou_crm_popup_prisma', 'true' );

                            Swal.fire({
                                // CADASTRO CONCLUIDO COM SUCESSO
                                title: "Sucesso :)", // TITULO DO POP-UP
                                text: "Seu cadastro foi concluído e em breve você receberá nossas comunicações!", // TEXTO INSERIDO NO POP-UP
                                icon: "success", // LOGO DO POP-UP (SUCESSO)
                                confirmButtonText: "OKAY! CONTINUE.", // BOTAO DE ENCERRAMENTO
                            });
                        }, "2000");
                        emailInputCartinha.value = ''; // Limpa o valor do input de e-mail
                        emailInputCartinha.classList.remove("alerta");
                        emailInputCartinha.placeholder = "Digite seu email";
                    }, "1005");                        
                    /* Fim do envio do email */  
                }
            })
            .catch(
                error => function() {
                    console.error(error)

                    Swal.fire({
                        title: "Ops... :(", // TITULO DO POP-UP
                        text: "Não foi possível se cadastrar. Tente novamente mais tarde.", // TEXTO INSERIDO NO POP-UP
                        icon: "error", // LOGO DO POP-UP (ERRO)
                        confirmButtonText: "CONTINUAR NO SITE", // BOTAO DE ENCERRAMENTO,
                    });
                }
            );  
        } else {
            // Define foco no input do e-mail
            document.getElementById("crm-aviso-email-popup-menu").innerHTML = "*Preenchimento obrigatório.";
            document.getElementById("crm-aviso-email-popup-menu").classList.add("exibe-aviso");
            emailInputCartinha.classList.add("alerta");
            emailInputCartinha.placeholder = "O e-mail é obrigatório.";
            emailInputCartinha.focus();
        }
    }
});

//Captura de Dados - Popup Menu (com Prisma)
if( typeof endpointURL !== 'undefined') {
    fetch(endpointURL)
    .then(response => {
        if (!response.ok) {
            throw new Error('Erro ao obter os dados de geolocalização, ou o estado não foi encontrado.');
        }
        return response.json();
    })
    .then(estado_atual => {
        async function fetchData() {
            const dados = await fetch(hostname + '/api/banner-geolocalizacao.json');
            const json = await dados.json();

            for (const key in json) {
                if (json.hasOwnProperty(key)) {
                    if (key === estado_atual && json[estado_atual]['status'] == true) {
                        document.getElementById('crm-popup-menu').classList.add('variacao-prisma');
                        document.getElementById('crm-popup-menu-titulo').textContent = `Cadastre-se e garanta ` + json[estado_atual]['desconto'] + `% OFF*`;

                        const link = json[estado_atual]['link'];

                        subscribeButtonCartinha.id = "crm-btn-popup-menu-prisma";
                        $("#crm-btn-popup-menu-prisma").on("click", function () {
                            let validacaoEmail = false;

                            if ( document.getElementById( "crm-email-popup-menu" ).value ) {
                                if (validateEmail( document.getElementById( "crm-email-popup-menu" ).value )) {
                                    validacaoEmail = true;
                                } else {
                                    validacaoEmail = false;
                                    document.getElementById("crm-aviso-email-popup-menu").innerHTML = "*Digite um e-mail válido.";
                                    document.getElementById("crm-aviso-email-popup-menu").classList.add("exibe-aviso");
                                }
                            } else {
                                validacaoEmail = false;
                                emailInputCartinha.classList.add("alerta");
                                emailInputCartinha.placeholder = "O e-mail é obrigatório.";
                                emailInputCartinha.focus();
                            }

                            if (document.getElementById("crm-btn-popup-menu-prisma").hasAttribute('checked')) {
                                document.getElementById("crm-btn-popup-menu-prisma").removeAttribute('checked');
                                document.querySelector('#crm-popup-menu .btn-icone').innerHTML = '<span class="material-symbols-outlined"> radio_button_unchecked </span>';
                                // Inicia a animação da barra de progresso
                                const progressBarCartinha = document.querySelector('#crm-popup-menu .progress-bar');
                                progressBarCartinha.style.width = '0%';
                                progressBarCartinha.style.transition = 'width 1s linear';
                                clearTimeout(envioCartinha);
                            } else {
                                if( validacaoEmail ) {
                                    const url = btoa(window.location.href);
                                    const email = document.getElementById("crm-email-popup-menu").value;
                                    const btnPersonalizado = Swal.mixin({
                                        customClass: {
                                            container: "popup-prisma-crm",
                                            confirmButton: "btn btn-outline-primary",
                                        },
                                        buttonsStyling: false
                                    });

                                    var data = new FormData();
                                    data.append("email",email);
                                    data.append("componente","popup-menu");
                                    data.append("origem",origem);
                                    data.append("url",url);
                                    fetch("/salva-contato.php", {
                                        method: "POST",
                                        mode: 'cors',
                                        body: data
                                    })
                                    .then(response => response.json())
                                    .then(data => console.log(data))
                                    .catch(
                                        error => function() {
                                            console.error(error)

                                            btnPersonalizado.fire({
                                                title: "Ops... :(", // TITULO DO POP-UP
                                                text: "Não foi possível se cadastrar. Tente novamente mais tarde.", // TEXTO INSERIDO NO POP-UP
                                                icon: "error", // LOGO DO POP-UP (ERRO)
                                                html: `
                                                    <a class="btn btn-primary" href="${link}" target="_blank">QUERO APROVEITAR</a>
                                                `,
                                                confirmButtonText: "CONTINUAR NO SITE", // BOTAO DE ENCERRAMENTO,
                                            });
                                        }
                                    );
                                        // Inicia a animação da barra de progresso
                                    const progressBarCartinha = document.querySelector('#crm-popup-menu .progress-bar');
                                    progressBarCartinha.style.width = '100%';
                                    progressBarCartinha.style.transition = 'width 1s linear';
                                    envioCartinha = setTimeout(() => {
                                        document.querySelector('#btn-abrir-crm-popup-menu').classList.remove('com-notificacao');
                                        document.querySelector('#crm-popup-menu-titulo').innerHTML = '<span class="material-symbols-outlined"> mark_email_read </span>E-mail cadastrado!';
                                        document.querySelector('#crm-popup-menu-titulo').classList.add("d-flex", "justify-content-center", "align-items-center", "gap-3");
                                        document.querySelector('#crm-popup-menu-descricao').innerHTML = 'Em breve você receberá novidades e ofertas exclusivas, fique ligado!';
                                        document.querySelector('#crm-popup-menu .form-group').classList.add('d-none');
                                        document.querySelector('#crm-btn-popup-menu-prisma').classList.add('d-none'); 

                                        setTimeout(() => {
                                            // Reseta os estilos, sem o popup
                                            document.getElementById("crm-popup-menu").style.display = "none";
                                            document.body.classList.remove("modal-open");
                                            document.body.style.overflow = "scroll";
                                            sessionStorage.setItem( 'cadastrou_crm_popup_prisma', 'true' );

                                            btnPersonalizado.fire({
                                                // CADASTRO CONCLUIDO COM SUCESSO
                                                title: "Sucesso :)", // TITULO DO POP-UP
                                                text: "Seu cadastro foi concluído e em breve você receberá nossas comunicações!", // TEXTO INSERIDO NO POP-UP
                                                icon: "success", // LOGO DO POP-UP (SUCESSO)
                                                html: `
                                                    <a class="btn btn-primary" href="${link}" target="_blank">QUERO APROVEITAR</a>
                                                `,
                                                confirmButtonText: "OKAY! CONTINUE.", // BOTAO DE ENCERRAMENTO
                                            });
                                        }, "2000");
                                        emailInputCartinha.value = ''; // Limpa o valor do input de e-mail
                                        emailInputCartinha.classList.remove("alerta");
                                        emailInputCartinha.placeholder = "Digite seu email";
                                    }, "1005");                        
                                    /* Fim do envio do email */
                                }
                            }
                        }); 
                    }
                }
            }
        }
        fetchData();
    })
    .catch(error => {console.error('Erro:', error);})
}

// Versão sem notificação
if( sessionStorage.getItem( 'cadastrou_crm_popup_prisma' ) === 'true' ) {
    sessionStorage.setItem( 'crm_banner_fixo', 'true' );
    document.querySelector('#btn-abrir-crm-popup-menu').classList.remove('com-notificacao');
    document.querySelector('#crm-popup-menu-titulo').innerHTML = '<span class="material-symbols-outlined"> mark_email_read </span>E-mail cadastrado!';
    document.querySelector('#crm-popup-menu-titulo').classList.add("d-flex", "justify-content-center", "align-items-center", "gap-3");
    document.querySelector('#crm-popup-menu-descricao').innerHTML = 'Em breve você receberá novidades e ofertas exclusivas, fique ligado!';
    document.querySelectorAll('#crm-popup-menu .form-group').forEach(elemento => {
        elemento.classList.add('d-none');
    })
    document.querySelector('#crm-popup-menu .btn-popup-menu').classList.add('d-none'); 
}

// Ajuste de tela quando abrir Popup Menu
$('#btn-abrir-crm-popup-menu').on( 'click', function() {
    document.querySelector('body').classList.remove('modal-open');
    document.querySelector('body').style.overflow = 'scroll';
});