let opcoes_selecionadas_linha = [];
let opcoes_selecionadas_formato = [];

$('.filtro-produtos .opcao-filtro').on('click', function() {
    let filtro = $( this ).attr( 'id' ).replace( 'filtro-', '' );

    if( $(this).hasClass( 'filtro-linha' ) ) {
        let linha = filtro.replace( 'linha-', '' );
        opcoes_selecionadas_linha = [];
        opcoes_selecionadas_linha.push( linha );

        if( opcoes_selecionadas_formato.length > 0 ) {
            $( '.card-produto' ).not( `[data-linha="${linha}"][data-formato="${opcoes_selecionadas_formato[0]}"]` ).hide();
            $( `.card-produto[data-linha="${linha}"][data-formato="${opcoes_selecionadas_formato[0]}"]` ).show();
        } else {
            $( '.card-produto' ).not( `[data-linha="${linha}"]` ).hide();
            $( `.card-produto[data-linha="${linha}"]` ).show();
        } 
    } 
    
    if( $(this).hasClass( 'filtro-formato' ) ) {
        let formato = filtro.replace( 'formato-', '' );
        opcoes_selecionadas_formato = [];
        opcoes_selecionadas_formato.push( formato );

        if( opcoes_selecionadas_linha.length > 0 ) {
            $( '.card-produto' ).not( `[data-formato="${formato}"][data-linha="${opcoes_selecionadas_linha[0]}"]` ).hide();
            $( `.card-produto[data-formato="${formato}"][data-linha="${opcoes_selecionadas_linha[0]}"]` ).show();
        } else {
            $( '.card-produto' ).not( `[data-formato="${formato}"]` ).hide();
            $( `.card-produto[data-formato="${formato}"]` ).show();
        }
    } 

    $( '#total-filtro-produtos' ).html( $( '.card-produto:visible' ).length );
    if( $( '.card-produto:visible' ).length == 0 ) {
        $( '.pagina-produtos .grid-produtos' ).append( '<b id="texto-sem-produtos">Sem produtos com esse filtro.</b>' );
    } else {
        $( '#texto-sem-produtos' ).remove();
    }
});

$( '#limpar-linha-produtos' ).on( 'click', function() {
    $( '.filtro-produtos .opcao-filtro.filtro-linha' ).attr( 'aria-checked', 'false' ).prop( 'checked', false );
    $( '.card-produto' ).show();

    opcoes_selecionadas_linha = [];
    $( '#total-filtro-produtos' ).html( $( '.card-produto:visible' ).length );
});

$( '#limpar-formato-produtos' ).on( 'click', function() {
    $( '.filtro-produtos .opcao-filtro.filtro-formato' ).attr( 'aria-checked', 'false' ).prop( 'checked', false );
    $( '.card-produto' ).show();
    
    opcoes_selecionadas_formato = [];
    $( '#total-filtro-produtos' ).html( $( '.card-produto:visible' ).length );
});