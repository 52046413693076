import fancybox from '@fancyapps/fancybox';

document.addEventListener('DOMContentLoaded', function () {
    $(".fancybox").fancybox();
    function toggleDropdownMobile() {
        $('#dropdown-link').toggleClass('open');
        $('#dropdown-menu').toggleClass('open start-d-none');
    }

    // Dropdown menu desktop
    function openDropdownDesktop() {
        $('#dropdown-link, #dropdown-menu').addClass('open');
        $('#dropdown-menu').removeClass('start-d-none').addClass('open');
    }

    function closeDropdownDesktop() {
        $('#dropdown-link, #dropdown-menu').removeClass('open');
        $('#dropdown-menu').removeClass('start-d-none').removeClass('open');
    }

    // Products dropdown on Main menu
    if ($(window).width() < 992) { // less than lg - Desktops devices
        $('#dropdown-menu .submenu, #dropdown-item').click(toggleDropdownMobile);
    } else {
        $( '#dropdown-menu .submenu' ).mouseenter( openDropdownDesktop ).mouseleave( closeDropdownDesktop );
        $( '#dropdown-item' ).mouseenter( openDropdownDesktop );
    }

    // Style active menu item by current page
    // $('#main-menu a[href$="/' + location.pathname.split("/")[3] + '"]').addClass('menu-item-active');
    $('#main-menu a[href$="' + location.pathname + '"]').addClass('menu-item-active');

    // Menu toggle animation on Mobile
    $('.navbar-toggler').click(function() {
        $(this).toggleClass('is-open is-closed');
    });

    window.addEventListener( 'scroll', scrollPost );

    function scrollPost() {
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;  
        let altura_conteudo_post = $( '.conteudo-post' ).height(); 
        var scrolled = ( winScroll / ( altura_conteudo_post + 90 ) ) * 100;
        if(document.getElementById("header-progress-bar")) {
            document.getElementById("header-progress-bar").style.width = scrolled + "%";
        }
    }

    // Resize Header when scrolls down
    var resizeHeader = () => {
        var scroll = $( window ).scrollTop();   
        var body = $( 'body' );
        var header = $( '#header' );
        
        if( scroll >= 50 ) {
            body.removeClass( 'body-expand' ).addClass( 'body-shrink' );
            header.removeClass( 'nav-expand' ).addClass( 'nav-shrink' );
        } else {
            body.removeClass( 'body-shrink' ).addClass( 'body-expand' );
            header.removeClass( 'nav-shrink' ).addClass( 'nav-expand' );
        }
    }

    $( document ).ready( function() {
        resizeHeader();
        $( window ).scroll( resizeHeader );
    });
});