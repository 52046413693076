// import $ from 'jquery';
import 'bootstrap/js/dist/modal';

// window.$ = $;
// window.jQuery = $;

document.addEventListener('DOMContentLoaded', () => {
    $('#modal-busca').modal({
        backdrop: false
    });

    $('#modal-bula').modal({
        backdrop: false
    });

    $( '.navegacao-dor .nav-link' ).on( 'click', function() {
        let id_produto = $( this ).attr( 'id' ).replace( 'button-','' );

        console.log( id_produto );

        $( '.navegacao-dor .nav-link' ).removeClass( 'active' );
        $( '.apresentacao-produto' ).removeClass( 'active' ).prop( 'aria-hidden', true );

        $( '#button-' + id_produto ).addClass( 'active' );
        $( '#produto-' + id_produto ).addClass( 'active' ).prop( 'aria-hidden', false );

        if( id_produto === 'buscopan' ) {
            $( '.escala' ).val( 1 );
        } else if( id_produto === 'buscoduo' ) {
            $( '.escala' ).val( 6 );
        } else {
            $( '.escala' ).val( 9 );
        }
    });

    function alterarProduto() {
        let nivel_dor = $( this ).val();
        $( '.escala' ).val( nivel_dor );

        $( '.navegacao-dor .nav-link' ).removeClass( 'active' );
        $( '.apresentacao-produto' ).removeClass( 'active' ).prop( 'aria-hidden', true );

        if( nivel_dor >= 1 && nivel_dor < 5 ) {
            $( '#button-buscopan' ).addClass( 'active' );
            $( '#produto-buscopan' ).addClass( 'active' ).prop( 'aria-hidden', false );
        } else if( nivel_dor >= 5 && nivel_dor <= 7 ) {
            $( '#button-buscoduo' ).addClass( 'active' );
            $( '#produto-buscoduo' ).addClass( 'active' ).prop( 'aria-hidden', false );
        } else if( nivel_dor > 7 ) {
            $( '#button-buscopan-composto' ).addClass( 'active' );
            $( '#produto-buscopan-composto' ).addClass( 'active' ).prop( 'aria-hidden', false );
        }
    }

    $( '.escala' ).on( 'change', alterarProduto );
    $( '#tickmarks option' ).on( 'click', alterarProduto );
});